import React,{useState, useEffect, useContext} from 'react';
import './App.css';
import {Container, Row, Col, Carousel, Card, Button, Accordion, AccordionContext} from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

import Components from './components';
import {MidTab1,MidTab2,MidTab3, MidTab4, MidTab5} from './components/midarea';
import FeedbackPatientPopup from './components/FeedbackPatientPopup';


const App = (props) => {

  const [popup, showPopup] = useState(true);
  const [tab,setTab] = useState(0);
  const [patientFeedback, setPatientFeedback] = useState(false);

  const tabSelect = (selectedIndex) => {
    // Expand mobile accordion if it hasn't been already.
    const mobileAccoridon = document.querySelector('.mobile-accordion .card:nth-child(' + (selectedIndex + 1) + ') .card-header button.target-collapsed');
    if (mobileAccoridon) {
      mobileAccoridon.click();
    }
    setTab(selectedIndex);
    window.scrollTo({
      top: document.getElementById('mid-tabs').offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }

  const initPage = () => {
    if(window.location.hash==='#pren-feedback'){
      setTab(2);
      showPopup(false);
    }
    if(window.location.search.indexOf('pren_feedback') !== -1) {
      setTab(2);
      showPopup(false);
    }
    if(window.location.hash==='#paen-feedback'){
      setTab(3);
      showPopup(false);
    }
    if(window.location.hash==='#paen-feedback'){
      setPatientFeedback(true);
    }

    if(window.location.hash==='#prescriberenrollment'){
      setTab(2);
      showPopup(false);
    }

    if(window.location.hash==='#patientenrollment'){
      setTab(3);
      showPopup(false);
    }
    if(window.location.hash==='#prescribexywavandxyrem'){
      setTab(4);
      showPopup(false);
    }
  }

  useEffect(initPage,[]);

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <button
      type="button"
      className={`btn btn-link ${isCurrentEventKey ? 'target-expanded' : 'target-collapsed'}`}
      onClick={decoratedOnClick}
      >
      {children}
      </button>
    );
  }

  const tabs = [
    {
      title: <label className="clickable">Prescriber<br />Materials</label>,
      title1: <>Prescriber Materials</>,
      content: <MidTab1 tabSelect={tabSelect} />,
      contentMobile: <MidTab1 tabSelect={tabSelect} />
    },
    {
      title: <label className="clickable">Patient<br />Materials</label>,
      title1: <>Patient Materials</>,
      content: <MidTab2 />,
      contentMobile: <MidTab2 />
    },
    {
      title: <label className="clickable">Prescriber<br />Enrollment</label>,
      title1: <>Prescriber Enrollment</>,
      content: <MidTab3 allowPopup={true} />,
      contentMobile: <MidTab3 allowPopup={false} />
    },
    {
      title: <label className="clickable">Patient<br />Enrollment</label>,
      title1: <>Patient Enrollment</>,
      content: <MidTab4 />,
      contentMobile: <MidTab4 />
    },
    {
      title: <label className="clickable">Prescribe<br />XYWAV and XYREM</label>,
      title1: <>Prescribe XYWAV and XYREM</>,
      content: <MidTab5 />,
      contentMobile: <MidTab5 />
    }
  ];

  const headerTab = (t,idx) => <Col key={idx} className="text-center clickable d-none d-sm-block" onClick={() => setTab(idx)}>
    <input type="radio" readOnly checked={tab===idx} /><span className="checkmark" /><br />
    {t.title}
  </Col>;

  const headerTabs = () => <div className="d-none d-sm-block"><Row className="header-tabs flex-column flex-sm-row" style={{zIndex: 200}}>
    {tabs.map(headerTab)}
  </Row></div>;

  const tabContent = () => <Row className="mid-area">
    <div className="pl-4 pr-2 pl-sm-3 pr-sm-3 w-100 d-none d-sm-block">
      <Carousel activeIndex={tab} controls={false} indicators={false} interval={null} touch={false}>
        {tabs.map((t,idx) => <Carousel.Item key={idx}>{t.content}</Carousel.Item>)}
      </Carousel>
    </div>

    <Accordion className="d-block d-sm-none mobile-accordion">
      {tabs.map((t,idx) => (
      <Card>
        <Card.Header>
          <ContextAwareToggle as={Button} variant="link" eventKey={`accordion-${idx}`}>
            {t.title1}
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={`accordion-${idx}`}>
          <Card.Body>{t.contentMobile}</Card.Body>
        </Accordion.Collapse>
      </Card>
      ))}
    </Accordion>
  </Row>;




  return (
    <Container className="p-0">
      <Components.Header tabSelect={tabSelect} menuTabs={tabs} tab={tab} onTabClick={setTab} />

        {headerTabs()}
        <a id="mid-tabs"></a>
        {tabContent()}
        {popup && <Components.HomePopup onHide={() => {showPopup(false)}} />}

      <Components.Footer />
      {patientFeedback!==false && <FeedbackPatientPopup form={2} onHide={() => setPatientFeedback(false)} />}
    </Container>
  );
}

export default App;
