import React,{useState, useEffect, useRef} from 'react';

import {Row, Col, Card, Table, Image} from 'react-bootstrap';

import PDFPopup from './../PDFPopup';
import FormPopup from './../FormPopup';
import FeedbackPopup from './../FeedbackPopup';
import PresInfoPopup from './../PresInfoPopup';

const MidTab3 = (props) => {

  const [pdf, setPdf] = useState(false);
  const [form, setForm] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [presInfo,showPresInfo] = useState(false);
  const prescriberenrollment = useRef(null);
  const scrollToPrescriberenrollment = () => {window.scrollTo(0, prescriberenrollment.current.scrollHeight + 130)}

  const initPage = () => {

    if(window.location.hash==='#pren-feedback' && props.allowPopup !== false){
      setFeedback(true);
    }
    if(window.location.search.indexOf('action=pren_feedback') !== -1 && props.allowPopup !== false) {
      setFeedback(true);
    }
    if(window.location.hash==='#prescriberenrollment'){
      setTimeout(scrollToPrescriberenrollment, 1000);
      //proscriberenrollment.current?.scrollIntoView({ block: "nearest", behavior: 'smooth' });
    }
    if(window.location.search.includes('showprescriberenrollmentform')){
      setForm(1);
    }
  }

  useEffect(initPage,[]);

  const showPDF = (e) => {
    e.preventDefault();
    setPdf(e.target.getAttribute("href"));
  }

  return <>
    <Row ref={prescriberenrollment} >
      <Col className="col-12 col-md-4 title">
        <label className="red">For Prescriber Use Only</label>
        PRESCRIBER ENROLLMENT
        <div className="title-details mt-2">
          <p>To become certified, each prescriber must complete a XYWAV and XYREM REMS Prescriber Enrollment Form once and submit it to the XYWAV and XYREM REMS via the options shown on the right.</p>
          <p>Before enrolling in the XYWAV and XYREM REMS, ensure you’re familiar by reading the <a href={`${process.env.PUBLIC_URL}/pdfs/USPI-xywav_en.pdf`} onClick={showPDF} className="px-1">XYWAV</a> and <a href={`${process.env.PUBLIC_URL}/pdfs/USPI-xyrem_en.pdf`} onClick={showPDF} className="px-1">XYREM</a> Prescribing Information <a href="#" onClick={(e) => {e.preventDefault();showPresInfo(true);}}>Prescriber Roles & Responsibilities</a> and the
            <a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Brochure.pdf`} onClick={showPDF} className="px-1">XYWAV and XYREM Prescriber Brochure.</a></p>

        </div>
      </Col>
      <div className="d-sm-none mb-3">
        <label className="red">For Prescriber Use Only</label>
        <div className="title-details mt-2">
          <p>To become certified, each prescriber must complete a XYWAV and XYREM REMS Prescriber Enrollment Form once and submit it to the XYWAV and XYREM REMS via the options shown on the right.</p>
          <p>Before enrolling in the XYWAV and XYREM REMS, ensure you’re familiar by reading the <a href={`${process.env.PUBLIC_URL}/pdfs/USPI-xywav_en.pdf`} onClick={showPDF} className="px-1">XYWAV</a> and <a href={`${process.env.PUBLIC_URL}/pdfs/USPI-xyrem_en.pdf`} onClick={showPDF} className="px-1">XYREM</a> Prescribing Information <a href="#" onClick={(e) => {e.preventDefault();showPresInfo(true);}}>Prescriber Roles & Responsibilities</a> and the
            <a href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Brochure.pdf`} onClick={showPDF} className="px-1">XYWAV and XYREM Prescriber Brochure.</a></p>

        </div>
      </div>
      <Col className="col-12 col-md-8 text-right bg-light p-3">
        <p className="title-details text-left p-3">The enrollment process for prescribers is quick, easy, and secure. Choose one of the two methods described below. Complete your enrollment form and submit it to the Certified Pharmacy for processing. For best results please use <b>Adobe Acrobat</b> to fill out the forms.</p>

        <Row noGutters>
          <Col className="col-12 col-sm-6 text-center blue-text">

            <label className="red px-5 py-1">Option 1</label>

            <Card variant="light" className="h-100 m-1">
              <Card.Body className="pb-0">
                <Table responsive borderless>
                  <tbody>
                  <tr>
                    <td className="w-30 p-0"></td>
                    <td className="w-30 p-0"><img src={`${process.env.PUBLIC_URL}/images/Body/icon-download.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Download:</b><br />
                      <a target="_blank" href={`${process.env.PUBLIC_URL}/pdfs/XYWAV-XYREM_REMS_Prescriber_Enrollment_Form.pdf`}>Prescriber Enrollment Form</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                    <td className="w-30 p-0"></td>
                    <td className="w-30 p-0"><img src={`${process.env.PUBLIC_URL}/images/Body/icon-scan-email.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Scan and Email to:</b><br />
                      <a href="mailto: ESSDSPrescribers@express-scripts.com">ESSDSPrescribers@express-scripts.com</a>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                    <td className="w-30 p-0"><img  src={`${process.env.PUBLIC_URL}/images/Body/icon-or.png`} /></td>
                    <td className="w-30 p-0"><img  src={`${process.env.PUBLIC_URL}/images/Body/icon-fax.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Fax to:<br />XYWAV and XYREM REMS</b><br />1-866-470-1744 (toll free)
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="h-10"></td>
                  </tr>
                  <tr>
                    <td className="w-30 p-0"><img   src={`${process.env.PUBLIC_URL}/images/Body/icon-or.png`} /></td>
                    <td className="w-30 p-0"><img   src={`${process.env.PUBLIC_URL}/images/Body/icon-mail.png`} /></td>
                    <td className="p-0 pl-1 text-left">
                      <b>Mail to:<br />XYWAV and XYREM REMS</b> <br />PO Box 66589<br />St. Louis, MO 63166-6589
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

          </Col>
          <Col className="col-12 col-sm-6 text-center blue-text">

            <label className="red px-5 py-1 mt-5 mt-sm-0">Option 2</label>

            <Card className="h-100 m-1">
              <Card.Body className="text-center">
              <p><b>Submit using DocuSign</b><br />
                By using DocuSign, the XYWAV and XYREM REMS can ensure that your personal information can stay safe, secure, and protected.</p>

                <Image fluid src="images/Body/icon-computer.png" className="px-5" />

                <a className="buttons" href="#" onClick={(e) => {e.preventDefault();setForm(1);}}><b>ACCESS ONLINE PRESCRIBER ENROLLMENT FORM</b></a>
              </Card.Body>
            </Card>

          </Col>
        </Row>

        {pdf!==false && <PDFPopup pdf={pdf} onHide={() => setPdf(false)} />}
        {form!==false && <FormPopup form={form} onHide={() => setForm(false)} />}
        {feedback!==false && <FeedbackPopup form={1} onHide={() => setFeedback(false)} />}
{presInfo && <PresInfoPopup onHide={() => {showPresInfo(false);}} />}



      </Col>
    </Row>
  </>
}

export default MidTab3;
